<template>
  <div class="me-wrapper">
    <div class="next_img_btn" @click="jump_next">
      <img src="http://pic.eaworks.cn/scbank/uploads/202336/6190465.png" />
    </div>
    <div class="_img _loading">
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  mixins: [   ],
  components: {},
  data() {
    return {
    };
  },
  created() {
    setTimeout(() => {
     this.jump_next();
    }, 3000)
  },

  mounted() {
  },
  methods: {
    jump_next(){
      let token = Cookies.get('admin_hdbank_test_token');
      if(token && token.length > 10){
          this.$router.push("/categoryList")
      } else {
          this.$router.push("/login")
      }
    }
  }
};
</script>

<style lang="less" scoped>
._loading {
  position: relative;
  ._loading_txt {
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 0.2rem;
    position: absolute;
    top: 16rem;
    left: 0;
    opacity: 0.4;
  }
}
.me-wrapper {
  background: url("http://pic.eaworks.cn/scbank/uploads/202337/6048541.png") no-repeat top center;
  background-size: 100% 100%;
  min-height: 100vh;
  font-size: 0.4rem;
  position: relative;
}
.next_img_btn{
  position: fixed;
  right: 0;
  top: 1.5rem;
  z-index: 999;
  img{
    width:3rem;
  }
}
</style>
